import * as React from 'react'
import { Layout } from '../components/Layout'
import { About } from '../components/About'

const AboutPage = () => (
  <Layout>
    <About />
  </Layout>
)

export default AboutPage
